import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';

// @mui/material components
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// core components
import Header from "components/Headers/Header.js";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { dataService } from "services/data-service";
import { useDispatch } from 'react-redux';
import { updateOrderFlow } from "../../generic/store";
import { NavigateBeforeRounded, NavigateNext } from "@mui/icons-material";
import { Grid, Tooltip, useMediaQuery, useTheme } from "@mui/material";

const OrderHeader = (props) => {
    const [productList, setProductList] = useState([]);
    const [previousWorkCenter, setPreviousWorkCenter] = useState(null);
    const [nextWorkCenter, setNextWorkCenter] = useState(null);
    const { WorkCenterId } = useParams();
    const [selectedFlow, setSelectedFlow] = useState({
        ProductId: 0,
        Name: 'All'
    });

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

    const dispatch = useDispatch(); // to update store values

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        if (localStorage.getItem('products')) {
            setProductList(JSON.parse(localStorage.getItem('products')));
        }
        else {
            let res = await getProductList();
            setProductList(res?.data);
        }


        if (localStorage.getItem('selected_flow')) {
            let selected_flow = JSON.parse(localStorage.getItem('selected_flow'));
            setSelectedFlow(selected_flow?.product);
        }
        else {

            setSelectedFlow({ ProductId: 0, Name: 'All' });

            //let res = await dataService('get', 'CommonList', `Query=productWorkcentersByProductId&Param1=${0}`);
            await getWorkCenterList();

        }

        //get workcenter and product list every 5 minutes
        //clearInterval();
        // for (var i = 1; i < 10; i++)
        // {
        //     window.clearInterval(i);
        // }


        const interval = setInterval(() => {
            getProductList();
            getWorkCenterList();
        }, 500000);

        if (window.productIntervals) {
            clearInterval(window.productIntervals);
            window.productIntervals = interval;
        }
        else {
            window.productIntervals = interval;
        }
    }

    useEffect(() => {
        let selected_flow = JSON.parse(localStorage.getItem('selected_flow'));
        let flowWorkcenters = selected_flow?.workcenters;
        let currFlow = {};
        if (selected_flow?.product?.ProductId > 0) {
            flowWorkcenters = flowWorkcenters?.filter((f) => {
                return Number(f.ProductId) === Number(selected_flow?.product?.ProductId);
            });
            flowWorkcenters = uniquWorkCenters(flowWorkcenters);
            currFlow = flowWorkcenters?.find((f) => {
                return Number(f.ProductId) === Number(selected_flow?.product?.ProductId) && Number(f.WorkCenterId) === Number(WorkCenterId);
            });
            let currFlowIdx = flowWorkcenters?.indexOf(currFlow);
            let nextWorkCenterData = flowWorkcenters?.[currFlowIdx + 1];
            let prevWorkCenterData = flowWorkcenters?.[currFlowIdx - 1];
            setNextWorkCenter(nextWorkCenterData);
            setPreviousWorkCenter(prevWorkCenterData);
        }
        else {
            flowWorkcenters?.sort((a, b) => {
                return a.ProductId - b.ProductId;
            })
            flowWorkcenters = uniquWorkCenters(flowWorkcenters);
            currFlow = flowWorkcenters?.find((f) => {
                return Number(f.WorkCenterId) === Number(WorkCenterId);
            })
            // If all is selected then Use File product flow.
            let flowWorkcentersFiles = flowWorkcenters?.filter((flw) => {
                return Number(flw?.ProductId) === 1;
            })
            let currFlowIdx = flowWorkcentersFiles?.indexOf(currFlow);
            let nextWorkCenterData = flowWorkcentersFiles?.[currFlowIdx + 1];
            let prevWorkCenterData = flowWorkcentersFiles?.[currFlowIdx - 1];
            setNextWorkCenter(nextWorkCenterData);
            setPreviousWorkCenter(prevWorkCenterData);
        }



    }, [WorkCenterId])

    const uniquWorkCenters = (workcentersArr) => {
        var items = workcentersArr;
        var result = [];
        for (var i = 0; i < items.length; i++) {
          let item = items[i];
          let findW = result.find(rs => rs.WorkCenterName === item.WorkCenterName);
          if (!findW) {
            result.push(item);
          }
        }
        if (result.length > 0) {
          result.sort((a, b) => a.Order - b.Order);
        }
        return result;
      };
      


    const getWorkCenterList = async () => {
        let res = await dataService('get', 'CommonList', `Query=productWorkcenters`);
        let product = { ProductId: 0, Name: 'All' };
        if (localStorage.getItem('selected_flow')) {
            let selected_flow = JSON.parse(localStorage.getItem('selected_flow'));
            product = selected_flow?.product;
        }
        if (res?.data) {
            let workcenters = res?.data;
            let selected_flow = {
                product: product,
                workcenters: workcenters
            }
            localStorage.setItem('selected_flow', JSON.stringify(selected_flow))
            dispatch(updateOrderFlow(selected_flow));

        }

        return res;
    }

    const getProductList = async () => {
        let res = await dataService('get', 'ProductList', null);
        if (res?.data) {
            localStorage.setItem('products', JSON.stringify(res?.data));
            return res;
        }

    }

    const handleSelect = async (ProductId) => {
        let item = productList.find((product) => { return Number(product?.ProductId) === Number(ProductId) })
        if (item)
            setSelectedFlow({ ProductId: item.ProductId, Name: item.Name });
        else
            setSelectedFlow({ ProductId: 0, Name: 'All' });



        if (localStorage.getItem('selected_flow')) {
            let selected_flow = JSON.parse(localStorage.getItem('selected_flow'));
            selected_flow.product = { ProductId: item?.ProductId ? item?.ProductId : 0, Name: item?.Name ? item?.Name : 'All' }
            localStorage.setItem('selected_flow', JSON.stringify(selected_flow))
            dispatch(updateOrderFlow(selected_flow));
        }
    };


    return (
        <>

            <Header />
            <Container
                maxWidth={false}
                component={Box}
                marginTop={isMobile ? "-10rem" : "-8rem"}
                className="px-10"
            >
                <FormControl component="fieldset" className="w-full">
                    <Grid className="flex flex-wrap items-center justify-start w-full">
                        <RadioGroup row aria-label="position" className="mb-0 text-black mr-auto ml-0" onChange={(e) => handleSelect(e?.target?.value)} name="position" value={selectedFlow?.ProductId}>
                            <FormControlLabel value={0} control={<Radio color="default" />} label="All" sx={{
                                "& .MuiFormControlLabel-label" : {
                                    fontSize : "12px !important"
                                }
                            }} />
                            {productList?.map((product, index) => {
                                return <FormControlLabel key={index} value={product?.ProductId} control={<Radio color="default" />} label={product?.Name} sx={{
                                    "& .MuiFormControlLabel-label" : {
                                        fontSize : isMobile ? "12px !important" : "14px !important"
                                    }
                                }}/>
                            })}
                        </RadioGroup>
                        <Grid className="ml-auto mr-0 flex flex-wrap items-center justify-end">
                            {previousWorkCenter &&
                                <Link to={'/admin/orderflow/' + previousWorkCenter?.WorkCenterName?.replace(' ', '-')?.toLowerCase() + '/' + previousWorkCenter?.WorkCenterId}>
                                    <Tooltip title="Previous" placement="top" arrow>
                                        <span className="bg-opacity-70 w-6 h-6 rounded-full bg-white flex items-center justify-center mr-1">
                                            <NavigateBeforeRounded className="w-5 h-auto" />
                                        </span>
                                    </Tooltip>
                                </Link>
                            }

                            {!props?.isHideHeader && nextWorkCenter &&
                                <Link to={'/admin/orderflow/' + nextWorkCenter?.WorkCenterName?.replace(' ', '-')?.toLowerCase() + '/' + nextWorkCenter?.WorkCenterId}>

                                    <Tooltip title="Next" placement="top" arrow>
                                        <span className="bg-opacity-70 w-6 h-6 rounded-full bg-white flex items-center justify-center ml-1">
                                            <NavigateNext className="w-5 h-auto" />
                                        </span>
                                    </Tooltip>

                                </Link>
                            }
                        </Grid>
                    </Grid>


                </FormControl>
            </Container>
        </>
    );
};

export default OrderHeader;