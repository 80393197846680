import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { DialogActions, FormLabel, LinearProgress, Typography } from '@mui/material';
import { CustomButton } from "components/Common/CustomButton";
import { dataService } from 'services/data-service';

import AWS from 'aws-sdk';

import { Grid, TextField, Box, FilledInput, FormGroup } from '@mui/material';
import ImageUpload from './ImageUpload';

function CreateCatalogueFileDialog({ onClose, open, selectedFile, afterAPICall }) {
    const [showSubmitLoading, setShowSubmitLoading] = useState(false)

    const [document, setDocument] = useState({
        SelectedFiles: open?.data?.FileName ? [{
            name: open?.data?.FileName,
            path: `${process.env.REACT_APP_AWS_S3_BUCKET_URL}${open?.data?.FilePath}${open?.data?.FileName}`,
            type: open?.data?.Extention,
            preview: `${process.env.REACT_APP_AWS_S3_BUCKET_URL}${open?.data?.FilePath}${open?.data?.FileName}`,
            size: open?.data?.Size,
            isUpdate: true
        }] : null,
        Title: open?.data?.Title || "",
        FeaturedImage: open?.data?.FeaturedImage ? [{
            name: open?.data?.FeaturedImage,
            path: `${process.env.REACT_APP_AWS_S3_BUCKET_URL}${open?.data?.FilePath}${open?.data?.FeaturedImage}`,
            type: open?.data?.Extention?.split(".")[open?.data?.Extention?.split(".")?.length - 1],
            preview: `${process.env.REACT_APP_AWS_S3_BUCKET_URL}${open?.data?.FilePath}${open?.data?.FeaturedImage}`,
            isUpdate: true
        }] : null
    });
    const [progressValue, setProgressValue] = useState(null)


    const [errors, setErrors] = useState({});

    const handleClose = () => {
        onClose();
    };

  
    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }


    const handleSubmit = async () => {

        const errors = {};

        if (!open?.data?.CatalogueFileId && !document.SelectedFiles) {
            errors['SelectedFiles'] = 'PDF is required';
        }
        if (!open?.data?.CatalogueFileId && !document.FeaturedImage) {
            errors['FeaturedImage'] = 'Featured Image is required';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        setShowSubmitLoading(true);

        try {
            const { REACT_APP_S3_BUCKET_NAME, REACT_APP_S3_REGION, REACT_APP_S3_ACCESS_KEY, REACT_APP_S3_SECRET_ACCESS_KEY } = process.env;
            AWS.config.update({ accessKeyId: REACT_APP_S3_ACCESS_KEY, secretAccessKey: REACT_APP_S3_SECRET_ACCESS_KEY });
            
            const s3 = new AWS.S3({ params: { Bucket: REACT_APP_S3_BUCKET_NAME }, region: REACT_APP_S3_REGION });
            const randomNumber = Math.floor(100 + Math.random() * 900);
            const timestamp = Date.now();
    
            const uploadToS3 = (file, key, contentType) => {
                return s3.putObject({
                    Bucket: REACT_APP_S3_BUCKET_NAME,
                    Key: key,
                    Body: file,
                    ACL: "public-read",
                    ContentType: contentType,
                    ContentDisposition: contentType === "application/pdf" ? "inline" : undefined
                }).on("httpUploadProgress", (evt) => {
                    if (contentType === "application/pdf") setProgressValue(parseInt((evt.loaded * 100) / evt.total));
                }).promise();
            };
    
            const fileExtension = document.SelectedFiles[0].name.split('.').pop();
            const imageExtension = document.FeaturedImage[0].name.split('.').pop();
            const filePath = `catalogue/cat-${selectedFile?.CatalogueId || 0}/`;
            await Promise.all([
                uploadToS3(document.SelectedFiles[0], `${filePath}catalogue_${randomNumber}_${timestamp}.${fileExtension}`, "application/pdf"),
                uploadToS3(document.FeaturedImage[0], `${filePath}catalogue_fi_${randomNumber}.${imageExtension}`, `image/${imageExtension}`)
            ]);
    
            const payload = {
                Title: document.Title,
                FileName: `catalogue_${randomNumber}_${timestamp}.${fileExtension}`,
                FilePath: filePath,
                MimeType: "application/pdf",
                Extention: fileExtension,
                Size: `${(document.SelectedFiles[0].size / (1024 * 1024)).toFixed(2)} MB`,
                FeaturedImage: `catalogue_fi_${randomNumber}.${imageExtension}`
            };
    
            const formData = new FormData();
            Object.keys(payload).forEach(key => formData.append(key, payload[key]));
    
            const res = await dataService('post', `CatalogueFileSaveV2/${selectedFile?.CatalogueId}/${open?.data?.CatalogueFileId || 0}`, formData);
    
            if (res && (res.status === 200 || res.status === 304)) {
                onClose();
                afterAPICall();
            }
        } catch (err) {
            console.error("Error uploading file:", err);
        } finally {
            setShowSubmitLoading(false);
        }
      
    };

    const handleChange = (e, name) => {
        const newDocument = { ...document };
        if (name === 'title') {
            newDocument['Title'] = e.target.value;
        } else if (name === 'file') {
            newDocument['SelectedFiles'] = e.target.files;
            const newErrors = { ...errors };
            delete newErrors['SelectedFiles'];
            setErrors(newErrors);
        } else if (name === 'image') {
            newDocument['FeaturedImage'] = e.target.files;
            const newErrors = { ...errors };
            delete newErrors['FeaturedImage'];
            setErrors(newErrors);
        }
        setDocument(newDocument);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open.isVisible}
            disableBackdropClick
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <div className={`border-2 flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className="px-4 py-3 pb-2 text-xl">
                    <div className="text-2xl">
                        Catalogue File
                    </div>
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-800 absolute right-0 top-2" onClick={handleClose} />
            </div>
            <DialogContent>
                <div className="mt-5">
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12}>
                            {progressValue && <LinearProgressWithLabel value={progressValue} />}
                        </Grid> */}
                        <Grid item xs={12} >
                            <FormGroup className='-mb-5'>
                                <div className="flex flex-wrap items-center mb-2">
                                    <FormLabel className="mr-3 mb-0">Title</FormLabel>
                                </div>
                                <TextField
                                    variant="filled"
                                    name="Title"
                                    component={Box}
                                    width="100%"
                                    placeholder='Title'
                                    marginBottom="1rem!important"
                                    value={document.Title}
                                    autoComplete="off"
                                    onChange={e => handleChange(e, 'title')}
                                >
                                    <Box
                                        paddingLeft="0.75rem"
                                        paddingRight="0.75rem"
                                        component={FilledInput}
                                        autoComplete="off"
                                        type="text"
                                    />
                                </TextField>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup className='mb-0'>
                                <div className="flex flex-wrap items-center mb-2">
                                    <FormLabel className="mr-3 mb-0">PDF</FormLabel>
                                </div>
                                <ImageUpload
                                    setDocument={setDocument}
                                    document={document}
                                    name="SelectedFiles"
                                    submit={() => { }}
                                    accept={{ 'application/pdf': [] }}
                                    displayPreview={true}
                                    displayUploadButton={false}
                                    setErrors={setErrors}
                                    error={errors}
                                />
                                {errors['SelectedFiles'] && (
                                    <div className="text-red-500 text-xs mt-1">
                                        {errors['SelectedFiles']}
                                    </div>
                                )}
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormGroup className='mb-0'>
                                <div className="flex flex-wrap items-center mb-2">
                                    <FormLabel className="mr-3 mb-0">Featured Image</FormLabel>
                                </div>
                                <ImageUpload
                                    setDocument={setDocument}
                                    document={document}
                                    name="FeaturedImage"
                                    submit={() => { }}
                                    accept={{
                                        'image/*': []
                                    }}
                                    displayPreview={false}
                                    displayUploadButton={false}
                                    setErrors={setErrors}
                                    error={errors}
                                />
                                {errors['FeaturedImage'] && (
                                    <div className="text-red-500 text-xs mt-1">
                                        {errors['FeaturedImage']}
                                    </div>
                                )}
                            </FormGroup>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions className='border-t border-gray-200'>
                <CustomButton disabled={true} autoFocus className='bg-green-500 text-white border-none mx-1 text-base' size="none" label={'Upload'} onClick={handleSubmit} iconClassName="w-5 h-auto" loading={showSubmitLoading} />
                <CustomButton autoFocus className='bg-red-500 text-white border-none mx-1 text-base' size="none" label={`Cancel `} onClick={handleClose} iconClassName="w-5 h-auto" />
            </DialogActions>
        </Dialog>
    );
};
export default CreateCatalogueFileDialog;