import axios from 'axios';  //client HTTP API
//import qs from "qs";
import Cookies from 'js-cookie';
//import {showVerificationDialog}  from "components/Dialoges/VerifiyOTPDialog";
import { showMessageSnackBar } from 'components/MessageBar/ErrorSnackBar';

export async function dataService(method, api, data, contentType = 'application/json') {
  if (method === 'post') {
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/api/${api}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Content-Type': contentType,
        'Access-Control-Max-Age': 86400
      },
      data: data ? data : {} //qs.stringify(data) //JSON.stringify(data)
    };
    try {
      let resData = await axios(config);
      if (resData.data)
        return resData.data;
      else
        return resData
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          // redirect to login if invalid token.
          Cookies.remove('token');
          window.location = '/auth/login';
        }
        if (error.response.status === 400) {
          let errorData = error.response.data;
          console.log('errorData: ', errorData);
          if (errorData) {
            errorData.is_error = true;
            return errorData;
          }
        }
      }
      else
      {
        if(error?.message === 'Network Error')
        {
          let errRes = {
            status: 500,
            message: `Error! Server can't be reached. Please try again later or contact server administrator!`
          }
          return errRes
        }
        else
        {
          let errRes = {
            status: 500,
            message: `Error! Please try again later or contact server administrator!`
          }
          return errRes
        }
      }

    }
  }
  
  else {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/api/${api}${data ? `?${data}` : ""}`,
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Content-Type': 'application/json',
      }
    };
    try {
      let resData = await axios(config);
      if (resData.data)
        return resData.data;
      else
        return resData
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          Cookies.remove('token');
          window.location = '/auth/login';
        }
        if (error.response.status === 400) {
          let errorData = error.response.data;
          console.log('errorData: ', errorData);
          if (errorData) {
            errorData.is_error = true;
            showMessageSnackBar('', errorData?.message ? errorData?.message : JSON.stringify(errorData), 'error',true)
            //return errorData;
          }
        }
      }
      else
      {
        if(error?.message === 'Network Error')
        {
          let errRes = {
            status: 500,
            message: `Error! Server can't be reached. Please try again later or contact server administrator!`
          }
          showMessageSnackBar('',errRes?.message, 'error',true)
          return errRes
        }
        else
        {
          let errRes = {
            status: 500,
            message: `Error! Please try again later or contact server administrator!`
          }
          showMessageSnackBar('',errRes?.message, 'error',true)
          return errRes
        }
      }

    }
  }

}

export async function verifyUserService(data) {

  var config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/api/verifyUser`,
    headers: {
      'Authorization': `Bearer ${data.Token}`,
      'Content-Type': 'application/json',
    },
    data: {VerificationCode: data.VerificationCode} //qs.stringify(data) //JSON.stringify(data)
  };
  try {
    let resData = await axios(config);
    if (resData.data)
      return resData.data;
    else
      return resData
  }
  catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        // redirect to login if invalid token.
        Cookies.remove('token');
        window.location = '/auth/login';
      }
      if (error.response.status === 400) {
        let errorData = error.response.data;
        console.log('errorData: ', errorData);
        if (errorData) {
          errorData.is_error = true;
          return errorData;
        }
      }
    }

  }

}

export async function getProductListForStore(){
  let res = await dataService('get', 'ProductList', null);
  //console.log('res: ', res);
  if(res?.data)
  {
      localStorage.setItem('products', JSON.stringify(res?.data));
      return res;
  } 
}

export async function getWorkCenterListForStore(){
  let res =  await dataService('get', 'CommonList', `Query=productWorkcenters`);
  let product = { ProductId: 0, Name: 'All' };
  if (localStorage.getItem('selected_flow')) {
      let selected_flow = JSON.parse(localStorage.getItem('selected_flow'));
      product = selected_flow?.product;
  }
  if (res?.data) {
      let workcenters = res?.data;
      let selected_flow = {
          product: product,
          workcenters: workcenters
      }
      localStorage.setItem('selected_flow', JSON.stringify(selected_flow))
      //dispatch(updateOrderFlow(selected_flow));
      
  }

  return res;
}


export async function getURLData(url, headers) {
  var config = {
    method: 'get',
    url: url,
    headers: headers ? headers : {}
  };
  try {
    let resData = await axios(config);
    if (resData.data)
      return resData.data;
    else
      return resData
  }
  catch (error) {
    console.log('getURLData error: ', error)
    if (error.response) {
      if (error.response.status === 400) {
        let errorData = error.response.data;
        console.log('errorData: ', errorData);
        if (errorData) {
          errorData.is_error = true;
          return errorData;
        }
      }
    }
    else
    {
      if(error?.message === 'Network Error')
      {
        let errRes = {
          status: 500,
          message: `Error! Server can't be reached. Please try again later or contact server administrator!`
        }
        return errRes
      }
      else
      {
        let errRes = {
          status: 500,
          message: `Error! Please try again later or contact server administrator!`
        }
        return errRes
      }
    }

  }

}

export function currencyFormat(value) {
  return new Intl.NumberFormat('en-IN').format(value);

}
