import React, { useEffect, useState } from 'react';

// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';
import { dataService } from 'services/data-service';
import { useHistory } from 'react-router-dom';
import { BorderClear, Delete as DeleteIcon, Edit as EditIcon, Visibility as VisibilityIcon } from "@mui/icons-material"

import { updatePreviousLocations } from 'generic/store';
import { useDispatch, useSelector } from 'react-redux';
import ViewDataDialog from 'components/dialoges/ViewDataDialog';
import CommonTable from 'components/Tables/CommonTable';
import { Typography } from '@mui/material';
import CatalogueFileListDialog from './components/CatalogueFileListDialog';
import QRCodeDialog from './components/QRCodeDialog';

const useStyles = makeStyles(componentStyles);

const CatalogueData = () => {
  const previousLocationState = useSelector(
    (state) => state?.previousLocationsStore?.previous_locations
  );
  const dispatch = useDispatch(); 


  const classes = useStyles();
  const [customerList, setCustomerList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [open, setOpen] = useState(false);

  const [openFilesDialog, setOpenFilesDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null)

  const [openQRCodeDialog, setOpenQRCodeDialog] = useState({
    isVisible: false, url: null, data: null
  });
  const history = useHistory();

  const [searchText, setSearchText] = useState(
    previousLocationState?.searchText || ''
  );
  const [page, setPage] = useState(previousLocationState?.page || 1);
  const [orderBy, setOrderByData] = useState(
    previousLocationState?.orderBy || 'CreatedAt'
  );
  const [order, setOrderData] = useState(
    previousLocationState?.order || 'desc'
  );
  const [offset, setOffset] = useState(previousLocationState?.offset || 10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [viewDialogData] = useState({
    labels: [],
    data: {},
  });
  const columns = [
    {
      id: 'Title',
      numeric: false,
      disablePadding: true,
      label: 'Title',
      dataType: 'string',
      Component: ({ row }) => (
        <div>
          <Typography
          onClick={viewAction}
          >
            {row?.Title}
          </Typography>
        </div>
      ),
    },

    {
      id: 'Description',
      numeric: false,
      disablePadding: true,
      label: 'Description',
      dataType: 'string',
    }
  ];

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload]);

  const fetchData = async () => {
    dispatch(updatePreviousLocations(null));
    setIsLoading(true);
    let res = await dataService(
      'get',
      'CatalogueList',
      `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`
    );
    setCustomerList(res?.data);
    setTotalRecord(res?.totalRecord);
    setIsLoading(false);
  }

  const handleEdit = (row) => {
    history.push(`update-catalogue/${row?.CatalogueId}`);
  };

  const viewAction = (row) => {
    setSelectedFile(row)
    setOpenFilesDialog(true)
  };

  const handleDelete = async (row) => {
    let res = await dataService(
      'post',
      `CatalogueDelete/${row.CatalogueId}`
    );
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };


  const handleShowQrCode = async (row) => {
    setOpenQRCodeDialog({ isVisible: true, url: `${process.env.REACT_APP_AWS_S3_BUCKET_URL}${row?.QRCodeFilePath}/${row?.QRCode}`, data : row })
  };

  let actionList = [
    {
      label: 'View',
      icon: VisibilityIcon,
      iconColor: 'purple-500',
      action: viewAction,
    },
    {
      label: 'Edit',
      icon: EditIcon,
      iconColor: 'blue-500',
      action: handleEdit,
    },
    { label: 'QRCode', icon: BorderClear, iconColor: 'black', action: handleShowQrCode },
    {
      label: 'Delete',
      icon: DeleteIcon,
      iconColor: 'red-500',
      action: handleDelete,
      isConfirmAction: true,
    },
  ];

  const handleDialogCLose = () => {
    setOpenFilesDialog(false)
  }

  const handleCloseQrcode = () => {
    setOpenQRCodeDialog({ isVisible: false, url: null })
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          title="Catalogue List"
          columns={columns}
          rows={customerList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          searchText={searchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
          view="catalogue"
          actions={actionList}
          viewURL="create-catalogue"
        />
      </Container>
      <ViewDataDialog
        open={open}
        onClose={setOpen}
        viewDialogData={viewDialogData}
      />
      {openFilesDialog &&
        <CatalogueFileListDialog
          title="Catalogue Files"
          onClose={handleDialogCLose}
          open={openFilesDialog}
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
        />
      }
      {openQRCodeDialog.isVisible &&
          <QRCodeDialog 
            open={openQRCodeDialog}
            onClose={() => {
              setOpenQRCodeDialog({isVisible : false, url : null, data : null})
            }}
          />
      }
    </>
  );
};

export default CatalogueData;

